import { Project } from '@/app/models/project';
import { RuleTypeConfig } from '@/app/models/ruleTypeConfig';
import { useBranchQuery } from '@/app/services/branchApi';
import { useClusterQuery } from '@/app/services/clusterApi';
import { useGetProjectSubTypesQuery } from '@/app/services/projectSubTypeApi';
import { useGetProjectTypesQuery } from '@/app/services/projectTypeApi';
import { useCreateClassifyMutation } from '@/app/services/RuleClassificationApi';
import { useCreateRuleTypeConfigMutation, useUpdateRuleTypeConfigMutation } from '@/app/services/ruleTypeConfig';
import {
  Box, MenuItem,
  Select,
  SelectChangeEvent, styled, Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';



const Separator = styled('div')({
  height: '2px',
  backgroundColor: '#bababa',
  width: '100%',
  margin: 0,
  padding: 0,
});


type CreateRuleClassificationProps = {
  onClose: () => void;
  editingReversion: RuleTypeConfig;
};

export default function CreateRuleClassificationDrawer(props: CreateRuleClassificationProps) {
  const { onClose, editingReversion } = props;

  const { data: clusters } = useClusterQuery({ page: 0, size: 100 });
  const { data: branches } = useBranchQuery({ page: 0, size: 100 });
  const { data: projectTypes } = useGetProjectTypesQuery({ page: 0, size: 100, sortKey: 'name', sortDirection: 'ASC' });
  const { data: projectSubTypes } = useGetProjectSubTypesQuery({
    page: 0,
    size: 100,
    sortKey: 'name',
    sortDirection: 'ASC',
  });

  const { t } = useTranslation('ruleClassification');
  const [cluster, setCluster] = useState('');
  const [branch, setBranch] = useState('');
  const [projectType, setProjectType] = useState('');
  const [projectSubtype, setProjectSubtype] = useState('');

  const [costCenter, setCostCenter] = useState('');
  const [searchCostCenter, setSearchCostCenter] = useState('');
  const [selectedCostCenter, setSelectedCostCenter] = useState(
    editingReversion ? editingReversion.project : (null as unknown as Project),
  );

  const [createRuleTypeConfig] = useCreateRuleTypeConfigMutation();
  const [updateRuleTypeConfig] = useUpdateRuleTypeConfigMutation();

  useEffect(() => {
    if (editingReversion) {
      setProjectSubtype(editingReversion.projectSubType.name || (null as unknown as string));
    }
  }, [clusters, editingReversion]);

  const [createClassify] = useCreateClassifyMutation();

  const handleSubmit = async () => {
    const newProcessClassify = {
      projectSubType: projectSubtype
    }
    const response = await createClassify(newProcessClassify);
    if ('data' in response) {
      toast.success(
        t('classification.created.successfully', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
    onClose();
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh )',
        maxHeight: 'calc(100vh )',
        flexGrow: 'inherit',
        background: '#FAFAFA',
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
        <Box style={{ color: '#4B4B4B', fontSize: '18px', fontWeight: 'bold', marginBottom: '24px' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: 30,
            }}
          >
            {t('classify.incentivized')}
          </Typography>
          <Separator />
          <Typography sx={{
            fontWeight: 'bold',
            marginTop: '32px'
          }}>{t('type.of.sharing')}</Typography>
          <div style={{ color: '#AAAAAA', fontSize: '15px', width: '100%', marginTop: '8px' }}>
            {t('choose.the.type.of.fee.that.will.be.considered.in.the.fee.rules.as.an.incentivized.project')}
          </div>
        </Box>

        <Typography variant="h3">
          {t('project')}
        </Typography>
        <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
          <Box sx={{ flexGrow: 1, width: '50%', height: '30px' }}>
            <Select
              value={projectSubtype}
              size="small"
              placeholder={t("select.the.sharing.type") as string}
              sx={{ width: '100%', fontSize: '13px', marginTop: '4px' }}
              onChange={(event: SelectChangeEvent<string>) => {
                setProjectSubtype(event.target.value as string);
              }}
            >
              {projectSubTypes?.content &&
                projectSubTypes.content.map((projectSubType) => (
                  <MenuItem
                    sx={{
                      fontSize: '13px',
                    }}
                    key={projectSubType.code}
                    value={projectSubType?.name || (null as any)}
                  >
                    {projectSubType?.name || '---'}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Box>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button
          variant="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          sx={{ marginLeft: 2 }}
          disabled={!(projectSubtype)}
          variant="primary"
          onClick={() => {
            handleSubmit();
          }}
        >
          {editingReversion ? t('cancel') : t('save')}
        </Button>
      </Box>
    </Box>
  );
}
