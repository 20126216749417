import api from '@services/api';
import { RuleClassificationRequest, RuleClassificationResponse } from '../models/RuleClassification';

/**
 * <p>
 * API endpoints that cover all classification rule CRUD
 */
export const RuleClassificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    allRuleClassification: builder.query<Array<RuleClassificationResponse>, void>({
      query: () => ({
        url: encodeURI(`/salary/rule-classification/findAll`),
      }),
      providesTags: ['allRuleClassification'],
    }),
    CreateClassify: builder.mutation<RuleClassificationResponse, RuleClassificationRequest>({
      query: (body: RuleClassificationRequest) => ({
        url: `/salary/rule-classification/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags:['allRuleClassification'],
    }),
    DeleteClassify: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `/salary/rule-classification/delete?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags:['allRuleClassification'],
    }),
  }),
});

export const { useAllRuleClassificationQuery, useCreateClassifyMutation, useDeleteClassifyMutation } = RuleClassificationApi;
