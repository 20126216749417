import { Period } from '@app/models/period';
import api from '@services/api';

import { PaginationResponse } from '../models/pagination';

interface PeriodRequest {
  page: number;
  size: number;
  timestamp: number;
}

/**
 * <p>
 * API endpoints that cover all resource period related CRUD calls
 */
export const periodApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    currentPeriod: builder.query<PaginationResponse<Period>, number>({
      query: () => ({
        url: encodeURI(`/salary/period/search?page=0&size=12&sortKey=date&sortDirection=DESC`),
        method: 'GET',
      }),
      providesTags: ['Periods'],
    }),
    periods: builder.query<PaginationResponse<Period>, PeriodRequest>({
      query: (input: PeriodRequest) => ({
        url: encodeURI(`/salary/period/search?size=${input.size}&page=${input.page}&sortDirection=DESC`),
      }),
      providesTags: ['Periods'],
    }),
  }),
});

export const { useCurrentPeriodQuery, usePeriodsQuery } = periodApi;
