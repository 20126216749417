import Events from '@/pages/events';
import General from '@/pages/general';
import Processing from '@/pages/processing';
import Redirect from '@/pages/redirecting';
import PERMISSION_ROLES, { PAGE_NAMES } from '@constants/roles';
import RequireAuth from '@features/auth/RequireAuth';
import RequireLogin from '@features/auth/RequireLogin';
import Layout from '@pages/layout';
import Login from '@pages/login';
import PersonProductRoles from '@pages/personProductRoles';

import Exception from '@/pages/exceptions';
import ProjectExceptions from '@/pages/projectExceptions';
import Reversions from '@/pages/reversions';
import { Route, Routes } from 'react-router-dom';

import { Period } from '@/app/models/period';
import { useCurrentPeriodQuery } from '@/app/services/periodApi';
import { getCurrentDate } from '@/app/utils/dateUtils';
import RuleClassification from '@/pages/ruleClassification/RuleClassification';
import EmployeeEvents from '@pages/employeeEvents/EmployeeEvents';
import NotShared from '@pages/notShared/NotShared';
import { useEffect, useState } from 'react';

function AppRoute() {
  const currentPeriod = getCurrentDate();
  const [selectedPeriod, setSelectedPeriod] = useState<Period>();
  const { data, isSuccess } = useCurrentPeriodQuery(1);

  useEffect(() => {
    setSelectedPeriod(data?.content && data.content[0]);
  }, [data, setSelectedPeriod]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<RequireLogin />}>
        <Route element={<Layout />}>
          {/* public routes */}
          <Route path="/" element={<Redirect />} />

          <Route element={<RequireAuth roles={PERMISSION_ROLES[PAGE_NAMES.CONTROLADORIA]} />}>
            <Route path="/events" element={<Events />} />
            <Route path="/processing" element={<Processing />} />
            <Route path="/general" element={<General />} />
            <Route path="/exception" element={<Exception />} />
            <Route path="/projectExceptions" element={<ProjectExceptions />} />
            <Route path="/notShared" element={<NotShared />} />
            <Route path="/employeeEvents" element={<EmployeeEvents />} />
            <Route path="/reversions" element={<Reversions />} />
            <Route path="/classifyIncentivized" element={<RuleClassification />} />
          </Route>
          <Route element={<RequireAuth roles={PERMISSION_ROLES[PAGE_NAMES.ADMIN]} />}>
            <Route path="/permission-by-product" element={<PersonProductRoles />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoute;
