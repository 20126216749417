import { Period } from '@/app/models/period';
import { RuleTypeConfig } from '@/app/models/ruleTypeConfig';
import { useAllRuleClassificationQuery, useDeleteClassifyMutation } from '@/app/services/RuleClassificationApi';
import LateralDrawer from '@/components/common/LateralDrawer';
import PeriodDetails from '@/components/events/PeriodDetails';
import NewEditRuleClassificationScreen from '@/components/ruleClassification/NewEditRuleClassificationScreen';
import RuleClassificationTable from '@/components/tables/RuleClassification';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function RuleClassification() {
  const { t } = useTranslation('ruleClassification');
  const [selectedPeriod, setSelectedPeriod] = useState<Period>();

  const { data: data } = useAllRuleClassificationQuery();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editingReversion, setEditingReversion] = useState(null as unknown as RuleTypeConfig);
  const [deleteClassify] = useDeleteClassifyMutation();

  const handleDelete = (id: number) => {
    const response = deleteClassify(id);
    if ('data' in response) {
      toast.success(
        t('classification.deleted.successfully', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
      <PeriodDetails selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />
      <div style={{ marginTop: '36px', fontSize: '20px', fontWeight: 'bold' }}>
        {t('classify.incentivized')}
      </div>
      <div style={{ color: '#AAAAAA', fontSize: '16px', width: '90%', marginTop: '13px' }}>
        {t('purpose.this.configuration.allow.you.choose')}
        <strong>{t('types.of.sharings')}</strong>
        {t('to.which.we.musy.apply.the.rules.of')}
        <strong>{t('incentivized')}</strong>
        {t('in.other.words.during.the.execution.of.the.apportionment.rules.the.projects.classified.here.will.be.treated.as.incentive')}

      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            marginTop: '0.5rem',
            justifyContent: 'flex-end',
          }}
        >
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              variant="text"
              sx={{ color: '#f97c06', textTransform: 'none', fontWeight: 800, fontSize: 17, marginBottom: '1em' }}
              onClick={() => setShowCreateModal(true)}
            >
              + {t('add')}
            </Button>
          </Box>
        </Box>
      </div>

      <RuleClassificationTable
        rows={data || []}
        handleDelete={handleDelete}
      />

      <LateralDrawer
        onClose={() => {
          setShowCreateModal(false);
          setEditingReversion(null as unknown as RuleTypeConfig);
        }}
        open={showCreateModal}
        setOpen={setShowCreateModal}
        position="right"
      >
        <NewEditRuleClassificationScreen
          editingReversion={editingReversion}
          onClose={() => {
            setShowCreateModal(false);
            setEditingReversion(null as unknown as RuleTypeConfig);
          }}
        />
      </LateralDrawer>
    </div>
  );
}
export default RuleClassification;
